// deps
import { useEffect, useMemo, useRef } from "react";
import useSWR from "swr";
import useSWRInfinite from "swr/infinite";

// contexts
import { useRequestContext } from "../contexts/requestContext";

// libraries
import { buildFetcher } from "../libraries/utils/fetcher";

/**
 * @param {object} options
 * @param {string} options.key
 * @param {Function} options.normalizer
 * @param {object} options.options
 */
export function useResponse(options) {
  const { key, normalizer, options: swrOptions } = options;

  const fetcher = useFetcher();

  const staleReady = useRef(false);

  const response = useSWR(key, fetcher, {
    revalidateIfStale: staleReady.current,
    revalidateOnReconnect: false,
    revalidateOnFocus: false,
    dedupingInterval: 0,
    shouldRetryOnError: false,
    ...swrOptions,
  });

  useEffect(
    function () {
      if (!staleReady.current && key) {
        staleReady.current = true;
      }
    },
    [key]
  );

  const data = normalizer({ data: response.data?.body });

  return { ...response, data, key };
}

export function useInfiniteResponse(options) {
  const { getKey, normalizer, options: swrOptions } = options;

  const fetcher = useFetcher();

  const staleReady = useRef(false);

  const response = useSWRInfinite(getKey, fetcher, {
    revalidateIfStale: staleReady.current,
    revalidateOnFocus: false,
    dedupingInterval: 0,
    revalidateFirstPage: false,
    ...swrOptions,
  });

  useEffect(
    function () {
      if (!staleReady.current && getKey) {
        staleReady.current = true;
      }
    },
    [getKey]
  );

  const data = normalizer({ bulkResponse: response.data });

  return { ...response, data, getKey };
}

export function useFetcher() {
  const requestContext = useRequestContext();
  const fetcher = useMemo(() => buildFetcher(requestContext), [requestContext]);

  return fetcher;
}
