type UserHasEmailType = {
  user?: ReturnType<typeof import("../../normalizers/user").parseUserData>;
};

/**
 * Retourne vrai si l'utilisateur possède une adresse e-mail.
 * @param {object} param0
 */
const userHasEmail = ({ user }: UserHasEmailType) => {
  if (null !== user) {
    return user.hasEmail;
  }

  return false;
};

export default userHasEmail;
