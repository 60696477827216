import { ROOM_STATE_VALUE_DISABLED } from "../../../constants/room";

type RoomStateIsDisabledParam0Type = {
  room: ReturnType<typeof import("../../../normalizers/room").roomParseData>;
};

/**
 * Retourne vrai si l'état de l'hébergement est désactivé.
 * @param {object} param0
 * @param param0.room
 * @return {boolean}
 */
const roomStateIsDisabled = ({ room }: RoomStateIsDisabledParam0Type) => {
  return ROOM_STATE_VALUE_DISABLED === room.state;
};

export default roomStateIsDisabled;
