type RoomHasPicturesParam0Type = {
  room: ReturnType<typeof import("../../../normalizers/room").roomParseData>;
};

/**
 * Retourne vrai si l'hébergement a au moins une image.
 */
const roomHasPictures = ({ room }: RoomHasPicturesParam0Type): boolean =>
  room?.pictureList?.length > 0;

export default roomHasPictures;
