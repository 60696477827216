type RoomHasReviewsParam0Type = {
  room: ReturnType<typeof import("../../../normalizers/room").roomParseData>;
};

/**
 * Retourne vrai si l'hébergement a des commentaires.
 */
const roomHasReviews = ({ room }: RoomHasReviewsParam0Type): boolean => {
  return room?.reviews > 0;
};

export default roomHasReviews;
