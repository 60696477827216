type RoomHasHandicapsParam0Type = {
  room: ReturnType<typeof import("../../../normalizers/room").roomParseData>;
};

/**
 * Retourne vrai si l'hébergement a au moins un support du handicap.
 */
const roomHasHandicaps = ({ room }: RoomHasHandicapsParam0Type): boolean =>
  room?.handicapList?.length > 0;

export default roomHasHandicaps;
