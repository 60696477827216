// constants
import { ROOM_NATURE_VALUE_PARENT } from "../../../constants/room";

type RoomIsNatureParentParam0Type = {
  room: ReturnType<typeof import("../../../normalizers/room").roomParseData>;
};

/**
 * Retourne vrai si l'hébergement est un parent (une super-structure).
 */
const roomIsNatureParent = ({ room }: RoomIsNatureParentParam0Type): boolean =>
  room?.nature === ROOM_NATURE_VALUE_PARENT;

export default roomIsNatureParent;
