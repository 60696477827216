import { ROOM_RANKING_TYPE_CITYBREAK } from "../../../constants/room";

type RoomStateIsDisabledParam0Type = {
  room: ReturnType<typeof import("../../../normalizers/room").roomParseData>;
};

/**
 * Retourne vrai si le type de ranking est citybreak.
 * @param {object} param0
 * @param param0.room
 * @return {boolean}
 */
const roomIsRankingCityBreak = ({ room }: RoomStateIsDisabledParam0Type) => {
  return ROOM_RANKING_TYPE_CITYBREAK === room.ranking.type;
};

export default roomIsRankingCityBreak;
