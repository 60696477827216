// constants
import {
  ROOM_RANKING_TYPE_EPI,
  ROOM_RANKING_TYPE_CITYBREAK,
  ROOM_TROPHIES,
} from "../../constants/room";

// libraries
import { fromString, fromDate } from "../../libraries/EnhancedDate";
import {
  convertSpinalCaseToPascalCase,
  normalizeUrl,
} from "@cloudspire/legacy-shared/src/libraries";

// helpers
import { roomGetBestColor } from "../../helpers/room";

// normalizers
import { userParseResource } from "../user";

export function getRanking({ room }) {
  const rankingType =
    null == room?.epi && room?.ranking
      ? ROOM_RANKING_TYPE_CITYBREAK
      : room?.metas?.rankingType;

  let rankingValue = null;

  switch (rankingType) {
    case ROOM_RANKING_TYPE_EPI: {
      rankingValue = Number(room?.epi);
      break;
    }

    case ROOM_RANKING_TYPE_CITYBREAK: {
      rankingValue = room?.ranking;
      break;
    }
  }

  return {
    type: rankingType,
    value: rankingValue,
  };
}

function getFeatureListByType({ data, featureIdListByType, features }) {
  const featureTypeList = Object.keys(featureIdListByType);

  const featureListByType = {};

  for (const roomFeature of data?.roomFeatures ?? []) {
    const roomFeatureId = roomFeature?.id;

    const featureType = featureTypeList.find((featureType) =>
      featureIdListByType[featureType].includes(roomFeatureId)
    );

    // Fonctionnalité récupérer via la configuration.
    const feature = features[roomFeatureId];

    if (undefined !== featureType && undefined !== feature) {
      if (!Array.isArray(featureListByType[featureType])) {
        featureListByType[featureType] = [];
      }

      featureListByType[featureType].push({
        id: feature.id,
        type: featureType,
        name: feature.name,
        distance: roomFeature.distance,
        icon:
          null !== feature.icon
            ? convertSpinalCaseToPascalCase(`icon-${feature.icon}-o`)
            : null,
        searchable: feature?.searchable,
        useDistance: feature?.useDistance,
      });
    }
  }

  return featureListByType;
}

function getOptionList({ data }) {
  const optionList = [];

  for (const option of Object.values(data?.options ?? {})) {
    if (option.strategy) {
      optionList.push({
        id: option?.id,
        legacy_id: option?.legacy_id,
        type: option?.type,
        name: option?.name,
        about: option?.about,
        description: option?.description,
        available_for: option?.available_for,
        required: option?.required,
        required_for: option?.required_for,
        available_days: option?.available_days,
        max_quantity: option?.max_quantity,
        strategy: option?.strategy,
        periods: option?.periods,
        amount: parseFloat(option?.amount),
        payable_on_spot: option?.payable_on_spot,
        price: Number(option?.amount),
        is_restricted_by_periods: option?.is_restricted_by_periods,
        isBookable: option?.isBookable ?? true,
      });
    }
  }

  return optionList;
}

function getPieces({ data }) {
  return (data?.pieces ?? []).map((piece) => {
    const floor = parseInt(piece?.floor);
    const surface = parseFloat(piece?.surface);

    return {
      name: piece?.name,
      about: piece?.about,
      surface: isNaN(surface) ? null : surface,
      floor: isNaN(floor) ? null : floor,
      beds80: piece?.beds80,
      beds90: piece?.beds90,
      beds120: piece?.beds120,
      beds140: piece?.beds140,
      beds160: piece?.beds160,
      beds180: piece?.beds180,
      beds200: piece?.beds200,
      bedsOverlaid: piece?.bedsOverlaid,
    };
  });
}

/**
 * @param {object} param0
 * @param {object} param0.data
 * @param {ReturnType<typeof import("../configuration/parseData").default>} param0.configuration
 */
export default function roomParseResource({ data, configuration }) {
  if (!data) {
    return null;
  }

  const { features, featureIdListByType } = configuration.room;

  return {
    reference: data?.reference,
    parentReference: data?.parentRef ?? null,
    ranking: getRanking({ room: data }),
    star: Number(data?.star ?? 0),
    slug: data?.slug,
    channelId: data?.channelId,
    about: data?.about,
    title: data?.title,
    surface: Number(data?.surface ?? 0),
    type: data?.type,
    infos: data?.infos,
    trophyList: (data?.trophies ?? []).map(
      (trophyName) => ROOM_TROPHIES[trophyName]
    ),
    bestColor: roomGetBestColor({
      categoryList: data?.categories ?? [],
    }),
    name: data?.name,
    children: (data?.childs ?? []).map((roomChild) =>
      roomParseResource({
        data: roomChild,
        configuration,
      })
    ),
    categoryList: data?.categories ?? [],
    mainCategory: data?.metas?.mainCategory,
    featureListByType: getFeatureListByType({
      data,
      featureIdListByType,
      features,
    }),
    reviews: Number(data?.metas?.commentStats?.qte ?? "0"),
    rate: Number(data?.metas?.commentStats?.average ?? "0"),
    beds: Number(data?.beds ?? "0"),
    chambers: Number(data?.chambers ?? "0"),
    minCapacity: Number(data?.minCapacity ?? "0"),
    maxCapacity: Number(data?.maxCapacity ?? "0"),
    pictureList: (data?.pictures ?? []).map((rawPicture) => ({
      urls: rawPicture?.urls,
      caption: rawPicture?.caption,
    })),
    movieList: (data?.movies ?? [])
      .filter((rawMovie) => rawMovie !== "")
      .map((rawMovie) => ({
        uri: rawMovie,
      })),
    nature: data?.nature,
    distance: Number(data?.metas?.distance ?? "0"),
    pieceList: getPieces({ data }),
    recentlyAdded: data?.created
      ? fromString(data.created, "Y-m-d H:i:s").monthDiff(fromDate()) <= 6
      : false,
    arePetsAllowed: Boolean(Number(data?.pets ?? "0")),
    hostTable: Number(data?.hostTable ?? "0"),
    customer: userParseResource({
      data: data?.customer,
    }),
    manager: userParseResource({
      data: data?.manager,
    }),
    city: data?.city,
    zipcode: data?.zipcode,
    lat: data?.lat ?? null,
    lng: data?.lng ?? null,
    handicapList: data?.handicaps ?? [],
    optionList: getOptionList({ data }),
    state: data?.state,
    registrationNumber: data?.registrationNumber,
    website: normalizeUrl(data?.website),
    channelIdList: (data?.channels ?? []).map((channel) => channel.channelId),
    mainChannel: (data?.channels ?? []).find((channel) => channel.main),
    channelList: data?.channels ?? [],
    isPro: Boolean(data?.isPro ?? false),
    code: data?.code ?? null,
    displayMap: Boolean(Number(data?.displayMap ?? "1")),
  };
}
