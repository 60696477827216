// normalizers
import roomParseResource from "./parseResource";

/**
 * Normalise la recherche de hébergements.
 * @param {object} param0
 * @param {object} param0.data
 * @param {Array} param0.data.data
 */
export default function roomParseSearch({ data, configuration }) {
  return {
    data: (data?.data ?? []).map((room) =>
      roomParseResource({
        data: room,
        configuration,
      })
    ),
  };
}

/**
 * Normalise la recherche de hébergements avec le chargement infini.
 * @param {object} param0
 * @param {object} param0.data
 * @param {Array} param0.data.data
 */
export function roomParseSearchInfinite({ bulkResponse, configuration }) {
  const body = {
    data: [],
    meta: {
      total: bulkResponse?.[0]?.body?.meta?.nb_results,
    },
  };

  bulkResponse = bulkResponse ?? [];

  for (const data of bulkResponse) {
    body.data.push(
      ...(data?.body?.data ?? []).map((room) =>
        roomParseResource({ data: room, configuration })
      )
    );
  }

  return body;
}

export function buildRoomParseSearch({ configuration }) {
  return function ({ data }) {
    return roomParseSearch({ data, configuration });
  };
}

export function buildRoomParseSearchInfinite({ configuration }) {
  return function ({ bulkResponse }) {
    return roomParseSearchInfinite({ bulkResponse, configuration });
  };
}
