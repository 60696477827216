/* Listing des noms de pages pour le seo */

export const SEO_PAGE_VALUE_HOME = "home";
export const SEO_PAGE_VALUE_CONTACT = "contact";
export const SEO_PAGE_VALUE_SEARCH = "search";
export const SEO_PAGE_VALUE_DEAL = "deal";
export const SEO_PAGE_VALUE_CITY = "city";
export const SEO_PAGE_VALUE_THEMATIC = "thematic";
export const SEO_PAGE_VALUE_CONTENT = "content";
export const SEO_PAGE_VALUE_BOOKING = "booking";
export const SEO_PAGE_VALUE_ACCOUNT = "account";
export const SEO_PAGE_VALUE_ROOM = "room";
