// constants
import { ROOM_CATEGORIES } from "@cloudspire/legacy-resources/src/constants/room";

// libraries
import {
  generateUri,
  capitalize,
} from "@cloudspire/legacy-shared/src/libraries";

// helpers
import { contentGetSlugFromCategoryAlias } from "@cloudspire/legacy-resources/src/helpers/content";

/**
 * Génère les différents liens du fil d'Ariane.
 */
const roomGenerateBreacrumbItemList = ({
  room,
  intl,
  route,
  configuration,
  router,
}) => {
  if (room) {
    return [
      {
        label: intl.formatMessage({
          id: "app.constants.breadcrumb.name.App.Action.Home",
        }),
        to: generateUri({
          router,
          name: "App.Action.Home",
          parameters: {
            lang: route.getParameter("lang"),
          },
        }),
      },
      ...(room.mainCategory
        ? [
            {
              label: capitalize(
                intl.formatMessage(
                  { id: "app.constants.room.category" },
                  {
                    category:
                      ROOM_CATEGORIES?.[room?.mainCategory]?.slug ??
                      room.mainCategory,
                  }
                )
              ),
              to: generateUri({
                router,
                name: "App.Action.RoomListWithSlug",
                parameters: {
                  lang: route.getParameter("lang"),
                  slug: contentGetSlugFromCategoryAlias({
                    contentList: configuration.channel.website.contents.list,
                    categoryAlias: room?.mainCategory,
                  }),
                },
              }),
            },
          ]
        : []),
      {
        label: room.city,
        to: generateUri({
          router,
          name: "App.Action.RoomList",
          parameters: {
            lang: route.getParameter("lang"),
          },
          query: {
            city: room.city,
            lat: room.lat,
            lng: room.lng,
          },
        }),
      },
      {
        label: room.name,
        to: generateUri({
          router,
          name: "App.Action.RoomWithSlug",
          parameters: {
            lang: route.getParameter("lang"),
            slug: room.slug,
          },
        }),
      },
    ];
  }

  return [];
};

export default roomGenerateBreacrumbItemList;
