import { ROOM_RANKING_TYPE_NONE } from "../../../constants/room";

type RoomIsRankingVisibleParam0Type = {
  room: ReturnType<typeof import("../../../normalizers/room").roomParseData>;
};

/**
 * Retourne vrai si l'hébergement affiche un rang (epi ou ranking).
 * @param {object} param0
 * @param param0.room
 */
const roomIsRankingVisible = ({ room }: RoomIsRankingVisibleParam0Type) =>
  Boolean(ROOM_RANKING_TYPE_NONE !== room.ranking.type && room.ranking.value);

export default roomIsRankingVisible;
