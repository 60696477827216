// constants
import {
  ROOM_CITYBREAK_RANKINGS,
  ROOM_RANKING_TYPE_CITYBREAK,
  ROOM_RANKING_TYPE_EPI,
} from "../../../constants/room";

/**
 * Retourne vrai si le ranking est valide.
 * @param {object} param0
 * @param {object} param0.room
 * @return {boolean}
 */
export default function roomIsRankingValid({ room }) {
  switch (room?.ranking?.type) {
    case ROOM_RANKING_TYPE_CITYBREAK:
      return room?.ranking?.value in ROOM_CITYBREAK_RANKINGS;
    case ROOM_RANKING_TYPE_EPI:
      return !isNaN(parseInt(room?.ranking?.value));
  }
  return true;
}
