// helpers
import roomGetCurrentRoomInChannel from "../getCurrentRoomInChannel";

type RoomIsBookableOnlineParam0Type = {
  room: ReturnType<
    typeof import("../../../normalizers/room/parseResource").default
  >;
  configuration: ReturnType<
    typeof import("../../../normalizers/configuration/parseData").default
  >;
};

/**
 * Retourne vrai si l'hébergement est réservable en ligne.
 * @param {object} param0
 * @param param0.room
 * @return {boolean}
 */
export default function roomIsBookableOnline({
  room,
  configuration,
}: RoomIsBookableOnlineParam0Type) {
  const roomInChannel = roomGetCurrentRoomInChannel({ room, configuration });
  return "1" === roomInChannel?.bookingSettings?.isBookable;
}
