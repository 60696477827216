type UserHasPhoneType = {
  user?: ReturnType<typeof import("../../normalizers/user").parseUserData>;
};

/**
 * Retourne vrai si l'utilisateur possède un numéro de téléphone.
 * @param {object} param0
 */
const userHasPhone = ({ user }: UserHasPhoneType) => {
  if (null !== user) {
    return Boolean(user.bestPhone) || Boolean(user.phone1);
  }

  return false;
};

export default userHasPhone;
