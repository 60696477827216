type RoomHasMapParam0Type = {
  room: ReturnType<typeof import("../../../normalizers/room").roomParseData>;
};

/**
 * Retourne vrai si l'hébergement peut s'afficher sur une carte.
 */
const roomHasMap = ({ room }: RoomHasMapParam0Type): boolean =>
  null != room?.lat &&
  null != room?.lng &&
  !isNaN(room?.lat) &&
  !isNaN(room?.lng);

export default roomHasMap;
