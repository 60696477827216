type RoomInChannelParam0Type = {
  room: ReturnType<typeof import("../../../normalizers/room").roomParseData>;
  channelIdList: string[];
};

/**
 * Retourne vrai si l’hébergement fait partie du channel courant ou de ses leaves.
 */
const roomInChannel = ({ room, channelIdList }: RoomInChannelParam0Type) => {
  return room.channelIdList.some((roomChannelId) =>
    channelIdList.includes(roomChannelId)
  );
};

export default roomInChannel;
