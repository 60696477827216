// constants
import {
  ROOM_FEATURE_CATEGORY_VALUE_SERVICE,
  ROOM_FEATURE_VALUE_ALL_INCLUSIVE_RATE,
} from "../../../constants/room";

// libraries
import { get } from "@cloudspire/legacy-shared/src/libraries";

/**
 * Retourne vrai si l’hébergement a un tarif tout inclus.
 * @param {object} param0
 * @param {object} param0.room
 * @return {boolean}
 */
export default function roomIsAllInclusiveRate({ room }) {
  return get(
    room,
    `featureListByType.${ROOM_FEATURE_CATEGORY_VALUE_SERVICE}`,
    []
  ).some(function (feature) {
    return ROOM_FEATURE_VALUE_ALL_INCLUSIVE_RATE === String(feature.id);
  });
}
