// constants
import { ROOM_NATURE_VALUE_CHILD } from "../../../constants/room";

type RoomIsNatureChildParam0Type = {
  room: ReturnType<typeof import("../../../normalizers/room").roomParseData>;
};

/**
 * Retourne vrai si l'hébergement est un enfant (une sous-structure).
 */
const roomIsNatureChild = ({ room }: RoomIsNatureChildParam0Type): boolean =>
  room.nature === ROOM_NATURE_VALUE_CHILD;

export default roomIsNatureChild;
