type RoomGetCurrentRoomInChannelParam0Type = {
  room: ReturnType<
    typeof import("../../normalizers/room/parseResource").default
  >;
  configuration: ReturnType<
    typeof import("../../normalizers/configuration/parseData").default
  >;
};

/**
 * Retourne l’objet RoomInChannel associé à l’hébergement et en prennant en compte la délégation.
 * @param {object} param0
 * @param {object} param0.room
 * @param {object} param0.configuration
 */
export default function roomGetCurrentRoomInChannel({
  room,
  configuration,
}: RoomGetCurrentRoomInChannelParam0Type) {
  return configuration?.channel?.website?.useBookingDelegation
    ? room?.mainChannel
    : (room?.channelList ?? []).find(
        (channel) => channel.channelId === configuration.channel.id
      );
}
