type RoomHasOptionsParam0Type = {
  room: ReturnType<typeof import("../../../normalizers/room").roomParseData>;
};

/**
 * Retourne vrai si l'hébergement a au moins une vidéo.
 */
const roomHasOptions = ({ room }: RoomHasOptionsParam0Type): boolean =>
  room?.optionList?.length > 0;

export default roomHasOptions;
