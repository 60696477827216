// helpers
import roomGetCurrentRoomInChannel from "../getCurrentRoomInChannel";

type RoomIsBookableOnlineParam0Type = {
  room: ReturnType<
    typeof import("../../../normalizers/room/parseResource").default
  >;
  configuration: ReturnType<
    typeof import("../../../normalizers/configuration/parseData").default
  >;
};

/**
 * Retourne la liste des contacts possibles.
 * @param {object} param0
 * @param {object} param0.configuration
 * @param {object} param0.room
 */
export default function roomGetContactList({
  room,
  configuration,
}: RoomIsBookableOnlineParam0Type) {
  const roomInChannel = roomGetCurrentRoomInChannel({ room, configuration });
  return roomInChannel?.bookingSettings?.contacts ?? [];
}
