type RoomHasPiecesParam0Type = {
  room: ReturnType<typeof import("../../../normalizers/room").roomParseData>;
};

/**
 * Retourne vrai si l'hébergement a au moins une pièce.
 */
const roomHasPieces = ({ room }: RoomHasPiecesParam0Type): boolean =>
  room?.pieceList?.length > 0;

export default roomHasPieces;
