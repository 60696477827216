// constants
import { ROOM_BOOKING_CONFIG_CONTACT_VALUE_OWNER } from "../../../constants/room";

// helpers
import roomGetContactList from "../getContactList";

type RoomMayContactOwnerParam0Type = {
  room: ReturnType<
    typeof import("../../../normalizers/room/parseResource").default
  >;
  configuration: ReturnType<
    typeof import("../../../normalizers/configuration/parseData").default
  >;
};

/**
 * Retourne vrai s'il est possible de contacter le propriétaire ou le mandataire en direct.
 * @note Cette configuration est pour l'instant injectée par le channel.
 * @param {object} param0
 * @param param0.room
 * @return {boolean}
 */
export default function roomMayContactOwner({
  room,
  configuration,
}: RoomMayContactOwnerParam0Type) {
  return roomGetContactList({ room, configuration }).some(
    (contact) => contact === ROOM_BOOKING_CONFIG_CONTACT_VALUE_OWNER
  );
}
