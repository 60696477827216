/**
 * Liste de états de l'estimation.
 */

export const PRICING_STATE_VALUE_AVAILABLE = "available";
export const PRICING_STATE_VALUE_UNAVAILABLE = "unavailable";
export const PRICING_STATE_VALUE_ONDEMAND = "ondemand";

export const PRICING_STATES = {
  [PRICING_STATE_VALUE_AVAILABLE]: PRICING_STATE_VALUE_AVAILABLE,
  [PRICING_STATE_VALUE_UNAVAILABLE]: PRICING_STATE_VALUE_UNAVAILABLE,
  [PRICING_STATE_VALUE_ONDEMAND]: PRICING_STATE_VALUE_ONDEMAND,
};
