type RoomHasMoviesParam0Type = {
  room: ReturnType<typeof import("../../../normalizers/room").roomParseData>;
};

/**
 * Retourne vrai si l'hébergement a au moins une vidéo.
 */
const roomHasMovies = ({ room }: RoomHasMoviesParam0Type): boolean =>
  room?.movieList?.length > 0;

export default roomHasMovies;
