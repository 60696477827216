/**
 * Retourne le téléphone à privilégier pour contacter un utilisateur.
 * @param {object} param0
 * @param {ReturnType<typeof import("../../normalizers/user").parseUserData>} param0.user
 */
const userGetPhone = ({ user }) => {
  if (null !== user) {
    if (user.phone1) {
      return user.phone1;
    }

    if (user.bestPhone) {
      return user.bestPhone;
    }
  }

  return null;
};

export default userGetPhone;
