type RoomHasParentParam0Type = {
  room: ReturnType<typeof import("../../../normalizers/room").roomParseData>;
};

/**
 * Retourne vrai si l'hébergement possède un parent.
 */
const roomHasParent = ({ room }: RoomHasParentParam0Type): boolean =>
  null !== room?.parentReference;

export default roomHasParent;
