function userParseResource({ data }) {
  if (!data) {
    return null;
  }

  return {
    firstname: data?.firstname,
    about: data?.about,
    lastname: data?.lastname,
    gender: data?.gender,
    locales: data?.locales ?? [],
    hasEmail: data?.hasEmail ?? Boolean(data?.email),
    bestPhone: data?.bestPhone,
    phone1: data?.phone1,
    avatar: {
      small: {
        url: data?.metas?.avatarUrls?.small,
      },
    },
  };
}

export default userParseResource;
