// constants
import { ROOM_RANKING_TYPE_EPI } from "@cloudspire/legacy-resources/src/constants/room";

type RoomIsRankingEpiParam0Type = {
  room: ReturnType<typeof import("../../../normalizers/room").roomParseData>;
};

/**
 * Retourne vrai si l'hébergement affiche des épis.
 * @param {object} param0
 * @param param0.room
 */
const roomIsRankingEpi = ({ room }: RoomIsRankingEpiParam0Type) =>
  ROOM_RANKING_TYPE_EPI === room.ranking.type;

export default roomIsRankingEpi;
