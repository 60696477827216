// libraries
import { fromDate } from "@cloudspire/legacy-resources/src/libraries/EnhancedDate";

type RoomCompressForLastSeenParam0Type = {
  room: ReturnType<
    typeof import("@cloudspire/legacy-resources/src/normalizers/room").roomParseData
  >;
};

type RoomCompressForLastSeenReturnType = {
  reference: string;
  slug: string;
  name: string;
  city: string;
  zipcode: string;
  bestColor: string;
  pictureList: Array<{ urls: any }>;
  setAt: string;
};

/**
 * Retourne un objet room avec des informations limitées pour l'affichage
 *   des hébergements dernièrement visités.
 */
const roomCompressForLastSeen = ({
  room,
}: RoomCompressForLastSeenParam0Type): RoomCompressForLastSeenReturnType => {
  const pictureList = [...room.pictureList];
  if (pictureList.length > 5) {
    // Stocke un maximum de 5 images.
    pictureList.length = 5;
  }

  return {
    reference: room.reference,
    slug: room.slug,
    name: room.name,
    city: room.city,
    zipcode: room.zipcode,
    bestColor: room.bestColor,
    pictureList,
    setAt: fromDate().format("Y-m-d"),
  };
};

export default roomCompressForLastSeen;
