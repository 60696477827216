type RoomHasChildrenParam0Type = {
  room: ReturnType<typeof import("../../../normalizers/room").roomParseData>;
};

/**
 * Retourne vrai si l'hébergement possède au moins 1 enfant.
 */
const roomHasChildren = ({ room }: RoomHasChildrenParam0Type): boolean => {
  return room?.children?.length > 0;
};

export default roomHasChildren;
