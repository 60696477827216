import { ROOM_STATE_VALUE_ENABLED } from "../../../constants/room";

type RoomStateIsEnabledParam0Type = {
  room: ReturnType<typeof import("../../../normalizers/room").roomParseData>;
};

/**
 * Retourne vrai si l'état de l'hébergement est activé.
 * @param {object} param0
 * @param param0.room
 * @return {boolean}
 */
function roomStateIsEnabled({ room }: RoomStateIsEnabledParam0Type) {
  return ROOM_STATE_VALUE_ENABLED === room?.state;
}

export default roomStateIsEnabled;
