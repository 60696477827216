// normalizers
import roomParseResource from "./parseResource";

export default function roomParseShow({ data, configuration }) {
  return {
    data: roomParseResource({ data: data?.data, configuration }),
  };
}

export function buildRoomParseShow({ configuration }) {
  return function ({ data }) {
    return roomParseShow({ data, configuration });
  };
}
