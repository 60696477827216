export const DEPARTMENT_LIST = {
  "01": {
    name: "Ain",
    region: "Auvergne-Rhône-Alpes",
  },
  "02": {
    name: "Aisne",
    region: "Nord-Pas-de-Calais-Picardie",
  },
  "03": {
    name: "Allier",
    region: "Auvergne-Rhône-Alpes",
  },
  "04": {
    name: "Alpes-de-Haute-Provence",
    region: "Provence-Alpes-Côte d'Azur",
  },
  "05": {
    name: "Hautes-Alpes",
    region: "Provence-Alpes-Côte d'Azur",
  },
  "06": {
    name: "Alpes-Maritimes",
    region: "Provence-Alpes-Côte d'Azur",
  },
  "07": {
    name: "Ardèche",
    region: "Auvergne-Rhône-Alpes",
  },
  "08": {
    name: "Ardennes",
    region: "Alsace-Champagne-Ardenne-Lorraine",
  },
  "09": {
    name: "Ariège",
    region: "Languedoc-Roussillon-Midi-Pyrénées",
  },
  10: {
    name: "Aube",
    region: "Alsace-Champagne-Ardenne-Lorraine",
  },
  11: {
    name: "Aude",
    region: "Languedoc-Roussillon-Midi-Pyrénées",
  },
  12: {
    name: "Aveyron",
    region: "Languedoc-Roussillon-Midi-Pyrénées",
  },
  13: {
    name: "Bouches-du-Rhône",
    region: "Provence-Alpes-Côte d'Azur",
  },
  14: {
    name: "Calvados",
    region: "Normandie",
  },
  15: {
    name: "Cantal",
    region: "Auvergne-Rhône-Alpes",
  },
  16: {
    name: "Charente",
    region: "Aquitaine-Limousin-Poitou-Charentes",
  },
  17: {
    name: "Charente-Maritime",
    region: "Aquitaine-Limousin-Poitou-Charentes",
  },
  18: {
    name: "Cher",
    region: "Centre-Val de Loire",
  },
  19: {
    name: "Corrèze",
    region: "Aquitaine-Limousin-Poitou-Charentes",
  },
  21: {
    name: "Côte-d'or",
    region: "Bourgogne-Franche-Comté",
  },
  22: {
    name: "Côtes-d'armor",
    region: "Bretagne",
  },
  23: {
    name: "Creuse",
    region: "Aquitaine-Limousin-Poitou-Charentes",
  },
  24: {
    name: "Dordogne",
    region: "Aquitaine-Limousin-Poitou-Charentes",
  },
  25: {
    name: "Doubs",
    region: "Bourgogne-Franche-Comté",
  },
  26: {
    name: "Drôme",
    region: "Auvergne-Rhône-Alpes",
  },
  27: {
    name: "Eure",
    region: "Normandie",
  },
  28: {
    name: "Eure-et-Loir",
    region: "Centre-Val de Loire",
  },
  29: {
    name: "Finistère",
    region: "Bretagne",
  },
  "2a": {
    name: "Corse-du-Sud",
    region: "Corse",
  },
  "2b": {
    name: "Haute-Corse",
    region: "Corse",
  },
  30: {
    name: "Gard",
    region: "Languedoc-Roussillon-Midi-Pyrénées",
  },
  31: {
    name: "Haute-Garonne",
    region: "Languedoc-Roussillon-Midi-Pyrénées",
  },
  32: {
    name: "Gers",
    region: "Languedoc-Roussillon-Midi-Pyrénées",
  },
  33: {
    name: "Gironde",
    region: "Aquitaine-Limousin-Poitou-Charentes",
  },
  34: {
    name: "Hérault",
    region: "Languedoc-Roussillon-Midi-Pyrénées",
  },
  35: {
    name: "Ille-et-Vilaine",
    region: "Bretagne",
  },
  36: {
    name: "Indre",
    region: "Centre-Val de Loire",
  },
  37: {
    name: "Indre-et-Loire",
    region: "Centre-Val de Loire",
  },
  38: {
    name: "Isère",
    region: "Auvergne-Rhône-Alpes",
  },
  39: {
    name: "Jura",
    region: "Bourgogne-Franche-Comté",
  },
  40: {
    name: "Landes",
    region: "Aquitaine-Limousin-Poitou-Charentes",
  },
  41: {
    name: "Loir-et-Cher",
    region: "Centre-Val de Loire",
  },
  42: {
    name: "Loire",
    region: "Auvergne-Rhône-Alpes",
  },
  43: {
    name: "Haute-Loire",
    region: "Auvergne-Rhône-Alpes",
  },
  44: {
    name: "Loire-Atlantique",
    region: "Pays de la Loire",
  },
  45: {
    name: "Loiret",
    region: "Centre-Val de Loire",
  },
  46: {
    name: "Lot",
    region: "Languedoc-Roussillon-Midi-Pyrénées",
  },
  47: {
    name: "Lot-et-Garonne",
    region: "Aquitaine-Limousin-Poitou-Charentes",
  },
  48: {
    name: "Lozère",
    region: "Languedoc-Roussillon-Midi-Pyrénées",
  },
  49: {
    name: "Maine-et-Loire",
    region: "Pays de la Loire",
  },
  50: {
    name: "Manche",
    region: "Normandie",
  },
  51: {
    name: "Marne",
    region: "Alsace-Champagne-Ardenne-Lorraine",
  },
  52: {
    name: "Haute-Marne",
    region: "Alsace-Champagne-Ardenne-Lorraine",
  },
  53: {
    name: "Mayenne",
    region: "Pays de la Loire",
  },
  54: {
    name: "Meurthe-et-Moselle",
    region: "Alsace-Champagne-Ardenne-Lorraine",
  },
  55: {
    name: "Meuse",
    region: "Alsace-Champagne-Ardenne-Lorraine",
  },
  56: {
    name: "Ain",
    region: "Bretagne",
  },
  57: {
    name: "Moselle",
    region: "Alsace-Champagne-Ardenne-Lorraine",
  },
  58: {
    name: "Nièvre",
    region: "Bourgogne-Franche-Comté",
  },
  59: {
    name: "Nord",
    region: "Nord-Pas-de-Calais-Picardie",
  },
  60: {
    name: "Oise",
    region: "Nord-Pas-de-Calais-Picardie",
  },
  61: {
    name: "Orne",
    region: "Normandie",
  },
  62: {
    name: "Pas-de-Calais",
    region: "Nord-Pas-de-Calais-Picardie",
  },
  63: {
    name: "Puy-de-Dôme",
    region: "Auvergne-Rhône-Alpes",
  },
  64: {
    name: "Pyrénées-Atlantiques",
    region: "Aquitaine-Limousin-Poitou-Charentes",
  },
  65: {
    name: "Hautes-Pyrénées",
    region: "Languedoc-Roussillon-Midi-Pyrénées",
  },
  66: {
    name: "Pyrénées-Orientales",
    region: "Languedoc-Roussillon-Midi-Pyrénées",
  },
  67: {
    name: "Bas-Rhin",
    region: "Alsace-Champagne-Ardenne-Lorraine",
  },
  68: {
    name: "Haut-Rhin",
    region: "Alsace-Champagne-Ardenne-Lorraine",
  },
  69: {
    name: "Rhône",
    region: "Auvergne-Rhône-Alpes",
  },
  70: {
    name: "Haute-Saône",
    region: "Bourgogne-Franche-Comté",
  },
  71: {
    name: "Saône-et-Loire",
    region: "Bourgogne-Franche-Comté",
  },
  72: {
    name: "Sarthe",
    region: "Pays de la Loire",
  },
  73: {
    name: "Savoie",
    region: "Auvergne-Rhône-Alpes",
  },
  74: {
    name: "Haute-Savoie",
    region: "Auvergne-Rhône-Alpes",
  },
  75: {
    name: "Paris",
    region: "Ile-de-France",
  },
  76: {
    name: "Seine-Maritime",
    region: "Normandie",
  },
  77: {
    name: "Seine-et-Marne",
    region: "Ile-de-France",
  },
  78: {
    name: "Yvelines",
    region: "Ile-de-France",
  },
  79: {
    name: "Deux-Sèvres",
    region: "Aquitaine-Limousin-Poitou-Charentes",
  },
  80: {
    name: "Somme",
    region: "Nord-Pas-de-Calais-Picardie",
  },
  81: {
    name: "Tarn",
    region: "Languedoc-Roussillon-Midi-Pyrénées",
  },
  82: {
    name: "Tarn-et-Garonne",
    region: "Languedoc-Roussillon-Midi-Pyrénées",
  },
  83: {
    name: "Var",
    region: "Provence-Alpes-Côte d'Azur",
  },
  84: {
    name: "Vaucluse",
    region: "Provence-Alpes-Côte d'Azur",
  },
  85: {
    name: "Vendée",
    region: "Pays de la Loire",
  },
  86: {
    name: "Vienne",
    region: "Aquitaine-Limousin-Poitou-Charentes",
  },
  87: {
    name: "Haute-Vienne",
    region: "Aquitaine-Limousin-Poitou-Charentes",
  },
  88: {
    name: "Vosges",
    region: "Alsace-Champagne-Ardenne-Lorraine",
  },
  89: {
    name: "Yonne",
    region: "Bourgogne-Franche-Comté",
  },
  90: {
    name: "Territoire de Belfort",
    region: "Bourgogne-Franche-Comté",
  },
  91: {
    name: "Essonne",
    region: "Ile-de-France",
  },
  92: {
    name: "Hauts-de-Seine",
    region: "Ile-de-France",
  },
  93: {
    name: "Seine-Saint-Denis",
    region: "Ile-de-France",
  },
  94: {
    name: "Val-de-Marne",
    region: "Ile-de-France",
  },
  95: {
    name: "Val-d'oise",
    region: "Ile-de-France",
  },
};
