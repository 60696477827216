import {
  ROOM_CATEGORIES,
  ROOM_DEFAULT_MAIN_COLOR,
} from "../../../constants/room";

type RoomGetBestColorType = {
  categoryList: string[];
};

/**
 * Détermine la meilleure couleur à associer à un hébergement.
 */
const roomGetBestColor = ({ categoryList }: RoomGetBestColorType) => {
  return categoryList.reduce(
    (roomCategoryAcc, roomCategory) => {
      const tempRoomCategory = ROOM_CATEGORIES[roomCategory];

      if (
        tempRoomCategory &&
        tempRoomCategory.weight > roomCategoryAcc.weight
      ) {
        return tempRoomCategory;
      }

      return roomCategoryAcc;
    },
    { weight: -1, mainColor: ROOM_DEFAULT_MAIN_COLOR }
  ).mainColor;
};

export default roomGetBestColor;
