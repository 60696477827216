type FindBestRoomUsingCapacityParam0Type = {
  /**
   * Liste des hébergements à comparer.
   */
  roomList: Array<
    ReturnType<
      typeof import("@cloudspire/legacy-resources/src/normalizers/room").roomParseData
    >
  >;
  /**
   * Capacité attendue.
   */
  capacity: number;
};

type FindBestRoomUsingCapacityReturnType = ReturnType<
  typeof import("@cloudspire/legacy-resources/src/normalizers/room").roomParseData
>;

/**
 * Détermine quelle est le meilleur hébergement à utiliser en utilisant
 *   sa capacité maximale et minimale.
 */
export const findBestRoomUsingCapacity = ({
  roomList,
  capacity,
}: FindBestRoomUsingCapacityParam0Type): FindBestRoomUsingCapacityReturnType => {
  return roomList.reduce((bestRoom, room) => {
    if (null === bestRoom) {
      // Si aucun hébergement n'est défini, on prend le premier.
      return room;
    }

    if (capacity >= room.minCapacity && capacity <= room.maxCapacity) {
      // Vérifie que la capacité de l'hébergement correspond au critère de capacité attendu.
      return room;
    }

    // Si on a pas trouvé meilleur hébergement, on continue d'envoyer le précédent.
    return bestRoom;
  }, null);
};

export default findBestRoomUsingCapacity;
