// constants
import { PRICING_STATE_VALUE_AVAILABLE } from "@cloudspire/legacy-resources/src/constants/pricing";

type RoomIsPricingAvailable = {
  pricing: ReturnType<
    typeof import("@cloudspire/legacy-resources/src/normalizers/pricing/estimate").pricingEstimateParseData
  >;
};

/**
 * Retourne vrai si le prix est disponible.
 * @param {object} param0
 * @param param0.pricing
 */
const roomIsPricingAvailable = ({ pricing }: RoomIsPricingAvailable) => {
  return pricing ? PRICING_STATE_VALUE_AVAILABLE === pricing.state : false;
};

export default roomIsPricingAvailable;
