// deps
import React from "react";

/**
 * Détermine les informations  en fonction de la page
 * @param configuration
 * @param page
 */
export default function getContentRelayOnConfiguration({
  configuration,
  page,
}) {
  const seoContentList = [];
  const title =
    configuration.channel.website.seo?.[page]?.title ??
    configuration.channel.website.seo?.default?.title;
  const description =
    configuration.channel.website.seo?.[page]?.desc ??
    configuration.channel.website.seo?.default.desc;
  if (title) {
    seoContentList.push(<title key="title">{title}</title>);
    seoContentList.push(
      <meta key="twitterTitle" name="twitter:title" content={title} />
    );
    seoContentList.push(
      <meta key="graphTitle" property="og:title" content={title} />
    );
  }
  if (description) {
    seoContentList.push(
      <meta key="description" name="description" content={description} />
    );
    seoContentList.push(
      <meta
        key="twitterDescription"
        name="twitter:description"
        content={description}
      />
    );
    seoContentList.push(
      <meta key="graphTitle" property="og:description" content={description} />
    );
  }
  return seoContentList;
}
